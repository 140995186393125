<template>
  <div class="moozumi-card">
    <img class="icon" :src="icon" />
    <div class="title">{{title}}</div>
    <div class="content">
      <template v-for="(item, index) in content">
        <div :key="index" :class="{'margin-top-20': index > 0}">{{item}}</div>
      </template>
      <!-- <div>Your payment strategy is unique to your business. So, how you optimize transactions should be customized to your particular needs too. But managing the complex logic to route transactions can be a challenge that drains your development resources.</div> -->
      <!-- <div class="margin-top-20">With moozumi, you control how authorization requests are reviewed or routed for approval. You can route for authorization approval based on specific criteria, prevent potentially problematic transactions from being submitted, and even retry transactions on an alternative gateway.</div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: ['icon', 'title', 'content']
}
</script>
<style scoped lang="scss">
@import '../styles/variables.scss';

.moozumi-card {
  background: $white;
  box-shadow: 0 0 77px 0 rgba(0,0,0,0.06);
  border-radius: 29px;
  width: 575px;
  max-width: 100%;
  padding: 30px;
  box-sizing: border-box;
  text-align: left;
  &.margin-top-100 {
    margin-top: 100px;
  }
  .icon {
    width: 60px;
  }
  .title {
    font-family: HelveticaNeue-Bold;
    font-size: 26px;
    color: $primary-color;
    letter-spacing: 0;
    text-align: left;
    margin-top: 18px;
  }
  .content {
    margin-top: 15px;
    font-family: HelveticaNeue;
    font-size: 18px;
    color: $primary-color;
    letter-spacing: 0;
    text-align: left;
    line-height: 32px;
  }
}
</style>
